Vue.component('occasion-filter', {
    mixins: [Mixins.datafilled],
    data: function () {
        return {
            currentBrand: null,
            brands: [],
            currentModel: null,
            models: [],
        }
    },
    mounted: function(){
        this.brands = window.occasions.models[this.$el.dataset.type];
        if(this.$refs.brand.dataset.selected) {
            this.currentBrand = this.$refs.brand.dataset.selected;
        }
    },
    watch: {
        currentBrand: function(){
            this.$refs.model.disabled = false;
            this.models = window.occasions.models.all[this.currentBrand];
            this.currentModel = this.models.length == 1 ? this.models[0] : this.$refs.model.dataset.selected;
        }
    },
    methods: {
        submit: function () {
            this.$el.querySelector('FORM').submit();
        },
    },
});
