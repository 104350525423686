Vue.component('occasion-categories', {
    data: function () {
        return {
            isOpen: true,
        }
    },
    mounted: function(){
        let that = this;

        [].forEach.call(this.$el.querySelectorAll('li'), function(li){
            li.addEventListener('click', function(e){
                [].forEach.call(that.$el.querySelectorAll('li'), function(el){
                    el.classList.remove('active');
                });

                e.target.classList.add('active');
            })
        });

    },
    watch: {

    },
    methods: {
        defaultFunction: function () {

        },
    },
});
