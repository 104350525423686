Vue.component("header-component", {
    data: function () {
        return {
            scrolled: false,
            menuExpanded: false
        }
    },
    watch: {
        menuExpanded(newValue) {
            if (newValue) {
                bodyScrollLock.disableBodyScroll(this.$refs.scrollLock);
            } else {
                bodyScrollLock.enableBodyScroll(this.$refs.scrollLock);
            }
        },
    },
    mounted: function () {
        let that = this;
        window.addEventListener('scroll', function () {
            that.watchScrollPosition();
        });

        this.watchScrollPosition();

        bodyScrollLock.clearAllBodyScrollLocks();

        window.addEventListener("resize", function() {
            if (window.innerWidth >= 768) {
                that.menuExpanded = false;
            }
        });
    },
    methods: {
        watchScrollPosition: function () {
            let that = this;
            const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

            if (scrollTop > 50) {
                that.scrolled = true;
            } else {
                that.scrolled = false;
            }
        },
    },
});