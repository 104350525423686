Vue.component('services-form', {
    props: ['packageList'],
    components: {
        'vue-recaptcha': VueRecaptcha
    },
    data: function () {
        return {
            isOpen: false,
            package: null,
            packages: [],
            hasCaptcha: true,
        }
    },
    mounted: function(){
        this.packages = this.packageList;
    },
    watch: {

    },
    methods: {
        setPackage: function (id) {
            this.package = id;
            this.$refs.package.dataset.state = 'filled';
            this.$root.MoveToInstance.move(this.$refs.formTitle)
        },
        submit: function () {

            this.$validator.validateAll().then(result => {
                let form = this.$refs.form;
                let analytics = null;

                if(form.dataset.analytics){
                    analytics = form.dataset.analytics;
                }

                if (result && analytics) {
                    if (!this.hasCaptcha) {
                        this.$root.submitForm(form, analytics);
                    } else {
                        this.$refs.recaptcha.execute();
                    }
                } else if (result) {
                    this.$root.submitForm(form);
                }
            });

            // recaptcha resetten
            this.$refs.recaptcha.reset();
        },
        onCaptchaVerified: function () {
            this.$root.submitForm(this.$refs.form);
        }
    },
});
