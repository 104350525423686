Vue.component('filter-slider', {
    data: function () {
        return {
            min: 0,
            minSelected: 0,
            minSelectedValue: 0,
            max: 100,
            maxSelected: 0,
            maxSelectedValue: 0,

            handleSize: 10,
            minPercentage: 0,
            maxPercentage: 100,


            dragMin: false,
            dragMax: false,

        }
    },
    mounted: function(){
        let that = this;

        that.minSelected = parseInt(that.$refs.min.value);
        that.maxSelected = parseInt(that.$refs.max.value);

        that.min = parseInt(that.$refs.min.getAttribute('data-total'));
        that.max = parseInt(that.$refs.max.getAttribute('data-total'));

        that.total = that.$refs.base.offsetWidth;

        window.addEventListener('mouseup', function(){
            that.dragMin = false;
            that.dragMax = false;
        })

        this.updateMin();
        this.updateMax();
    },
    watch: {

    },
    methods: {
        startdragmin: function(e){
            this.dragMin = true;
            this.updateMin(e);
        },
        starttouchmin: function(e){
            if(typeof e.touches !== 'undefined') {
                this.dragMin = true;
                this.updateMin(e.touches[0]);
            }
        },
        ondragmin: function(e){
            if(this.dragMin){
                this.updateMin(e);
            }
        },
        ontouchmin: function(e){
            if(typeof e.touches !== 'undefined'){
                this.dragMin = true;
                this.updateMin(e.touches[0]);
            }
        },
        stopdragmin: function(){
            this.dragMin = false;
        },

        updateMin: function(e){

            let that = this;
            let init = false;

            if(typeof e !== 'undefined'){

                var base            = that.$refs.base.getBoundingClientRect().left;
                var pos             = e.clientX - base;
                var percentage      = pos / (that.total/100);

            } else {

                var percentage      = 0;

            }

            if( percentage == 0 && that.min != that.minSelected ){
                percentage = ((that.minSelected - that.min) / (that.max - that.min)) * 100;
                that.minSelectedValue = that.minSelected.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
                init = true;
            }

            if(percentage < 1){
                percentage = 0;
            }

            if(percentage >= 0 && percentage < (that.maxPercentage - 2) && !init) {
                that.minPercentage      = percentage;

                that.minSelected        = that.min + (((that.max - that.min) / 100) * percentage);
                that.minSelectedValue   = that.minSelected.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
                that.$refs.min.value    = Math.round(that.minSelected);
            }

            if(percentage >= 0 && percentage < (that.maxPercentage - 2)){
                that.$refs.minHandle.style.left = percentage + '%';
                that.$refs.between.style.left = percentage + '%';
            }

            init = false;
        },


        startdragmax: function(e){
            this.dragMax = true;
            this.updateMax(e);
        },
        starttouchmax: function(e){
            if(typeof e.touches !== 'undefined') {
                this.dragMax = true;
                this.updateMax(e.touches[0]);
            }
        },
        ondragmax: function(e){
            if(this.dragMax){
                this.updateMax(e);
            }
        },
        ontouchmax: function(e){
            if(typeof e.touches !== 'undefined') {
                this.dragMax = true;
                this.updateMax(e.touches[0]);
            }
        },
        stopdragmax: function(e){
            this.dragMax = false;
        },
        updateMax: function(e){
            let that = this;
            var init = false;

            if(typeof e !== 'undefined'){

                var base            = that.$refs.base.getBoundingClientRect().left + (that.handleSize / 2);
                var pos             = e.clientX - base;
                var percentage      = pos / (that.total/100);

            } else {

                var percentage      = 100;

            }

            if( percentage == 100 && that.max != that.maxSelected ){
                percentage = (that.maxSelected / that.max) * 100;
                that.maxSelectedValue = parseInt(that.maxSelected).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
                init = true;
            }

            if(percentage > 99){
                percentage = 100;
            }

            if(percentage <= 100 && (that.minPercentage + 2) < percentage && !init) {
                that.maxPercentage = percentage;
                that.maxSelected = that.min + ((that.max - that.min) / 100) * percentage;
                that.maxSelectedValue = that.maxSelected.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
                that.$refs.max.value = Math.round(that.maxSelected);
            }


            if(percentage <= 100 && (that.minPercentage + 2) < percentage){
                that.$refs.maxHandle.style.left = percentage + '%';
                that.$refs.between.style.right = (100 - percentage) + '%';
            }

            init = false;
        }
    },
});
