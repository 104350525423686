Vue.component('filter-slider', {
    props: {
        range: {
            type: Object,
            required: true,
        },
        start: {
            type: Object,
            required: true,
        }
    },
    data: function () {
        return {
            min: this.start.min,
            max: this.start.max,
        }
    },
    mounted: function(){
        this.initSlider();
    },
    methods: {
        initSlider() {
            let slider = document.getElementById('min-max-slider');

            noUiSlider.create(slider, {
                start: [this.min, this.max],
                connect: true,
                range: this.range,
                step: 100,
                cssPrefix: 'filter-slider',
                cssClasses: {
                    target: "",
                    base: "__base",
                    origin: "__origin",
                    handle: "__handle",
                    handleLower: "__handle-lower",
                    handleUpper: "__handle-upper",
                    touchArea: "__touch-area",
                    horizontal: "--horizontal",
                    vertical: "--vertical",
                    background: "background",
                    connect: "__connect",
                    connects: "__connects",
                    ltr: "--ltr",
                    rtl: "--rtl",
                    textDirectionLtr: "--txt-dir-ltr",
                    textDirectionRtl: "--txt-dir-rtl",
                    draggable: "draggable",
                    drag: "--state-drag",
                    tap: "--state-tap",
                    active: "__handle--active",
                    tooltip: "__tooltip",
                    pips: "__pips",
                    pipsHorizontal: "__pips-horizontal",
                    pipsVertical: "_pips-vertical",
                    marker: "__marker",
                    markerHorizontal: "__marker-horizontal",
                    markerVertical: "__marker-vertical",
                    markerNormal: "__marker-normal",
                    markerLarge: "__marker-large",
                    markerSub: "__marker-sub",
                    value: "__value",
                    valueHorizontal: "__value-horizontal",
                    valueVertical: "__value-vertical",
                    valueNormal: "__value-normal",
                    valueLarge: "__value-large",
                    valueSub: "__value-sub"
                }
            });

            slider.noUiSlider.on('update', (values, handle, unencoded, tap, positions) => {
                this.min = Math.round(values[0]);
                this.max = Math.round(values[1]);
            });
        },
        formatAsPrice(number) {
            return new Intl.NumberFormat('nl', { style: 'currency', currency: 'EUR' })
                .format(number)
                .replace(',00', '');
        }
    },
});
