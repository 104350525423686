Vue.component('occasion-options', {
    data: function () {
        return {
            isOpen: true,
            listHeight: 0,
        }
    },
    mounted: function(){
        let that = this;
        that.listHeight = that.$el.querySelector('.occasion-options__list').offsetHeight;

        // initieel was het idee om een geanimeerde lijst te maken, door tijdgebrek hier vanaf gezien
        // height word geset om een bug met IE te omzeilen.
        that.$el.querySelector('.occasion-options__list').style.height = that.listHeight + 'px';
        that.isOpen = false;
    }
});
